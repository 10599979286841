import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update August 11, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` because I've not ensured its relevance
with the latest in Angular.js. I brought it back by
`}<a parentName="p" {...{
        "href": "https://twitter.com/ghettosoak/status/1027118531131916289"
      }}>{`request`}</a>{`.`}</p>
    <div dangerouslySetInnerHtml={{
      __html: `<h2>Directives</h2>
<h3>Restrict</h3>
<table>
  <thead>
    <tr>
      <th>Character</th>
      <th>Declaration Style</th>
      <th>Example</th>
    </tr>
  </thead>
  <tr>
    <td>E</td>
    <td>element</td>
    <td>&lt;hello to="world"&gt;&lt;/hello&gt;</td>
  </tr>
  <tr>
    <td>A</td>
    <td>attribute</td>
    <td>&lt;div hello="world"&gt;&lt;/div&gt;</td>
  </tr>
  <tr>
    <td>C</td>
    <td>class</td>
    <td>&lt;div class="hello:world"&gt;&lt;/div&gt;</td>
  </tr>
  <tr>
    <td>M</td>
    <td>comment</td>
    <td>&lt;!--directive:hello World --&gt;</td>
  </tr>
</table>
<h3>Scope</h3>
<table>
  <thead>
    <tr>
      <th>Scope Type</th>
      <th>Syntax</th>
      <th>Description</th>
    </tr>
  </thead>
  <tr>
    <td>existing scope</td>
    <td>scope: false (default)</td>
    <td>The existing scope for the directive's DOM element.</td>
  </tr>
  <tr>
    <td>new scope</td>
    <td>scope: true</td>
    <td>A new scope that inherits from your enclosing controller's scope prototypically. This scope will be shared with any other directive on your DOM element that request this kind of scope and can be used to communicate with them.</td>
  </tr>
  <tr>
    <td>isolate scope</td>
    <td>scope: { attributeName: 'BINDING_STRATEGY' } or { attributeAlias: 'BINDING_STRATEGY' + 'attributeName' }</td>
    <td>An isolate scope that inherits no properties from the parent, you can however access the parent scope using $parent.</td>
  </tr>
</table>
<h3>Scope Binding Strategies</h3>
<table>
  <thead>
    <tr>
      <th>Symbol</th>
      <th>Meaning</th>
    </tr>
  </thead>
  <tr>
    <td>@</td>
    <td>
      Pass this attribute as a string. You can bind values to the parent scope
      using {{ interpolation }}.
    </td>
  </tr>
  <tr>
    <td>=</td>
    <td>Data bind this property to the directive's parent scope.</td>
  </tr>
  <tr>
    <td>&amp;</td>
    <td>
      Pass in a function from the parent scope to be called later. Used to pass
      around lazily evaluated angular expressions.
    </td>
  </tr>
</table>
<h3>Require</h3>
<table>
  <thead>
    <tr>
      <th>Option</th>
      <th>Usage</th>
    </tr>
  </thead>
  <tr>
    <td>directiveName</td>
    <td>A camel-cased name that specifies which directive teh controller should come from. If our <dialog-title> directive needs to find a controller on it's parent <dialog> we would write "dialog".</td>
  </tr>
  <tr>
    <td>^</td>
    <td>By default, Angular gets the controller from the named directive on the same element. Adding this symbol says to walk up the DOM to find the directiev. For the dialog example with would need to add this symbol "^dialog". This means, look up till you find the parent dialog directive and give me that controller.</td>
  </tr>
  <tr>
    <td>?</td>
    <td>Makes the required controller optional, otherwise Angular would throw an exception if it couldn't find it.</td>
  </tr>
</table>`
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      